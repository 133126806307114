mamka = require('mamka')
# keen = require('keen-js')
delegate = require('dom-delegate')
dispatcher = require('shared/lib/dispatcher')
cookies = require('./cookies.coffee')

METRICS_PREFIX = window.TP_WL_MAMKA_PREFIX || "TP_WL"
HOTELS_METRICS_PREFIX = METRICS_PREFIX + "_HOTELS"

mamka('create', {
  'project_name': window.TP_WL_MAMKA_PROJECT_NAME || 'wl_search'
})

# keen_client = new Keen
#   projectId: '59631e66c9e77c000128d55e'
#   writeKey: '7CEBFA23795B50BE9447CB755773F93197BF060F03AE388CCF599DF435E2D3E100E4E4934ED60A5A7B3A30CB08136F166D6015E4B8646FB7ED924466AEDE3378F80047DEC9EB2F244B092454C3E83B8705A5847D997EF155FA7E21EA2845234A'

send_metric = (params = {}) ->
  goal = params.goal
  delete params.goal
  data = {'name': goal, 'meta': params}
  mamka('send_event', data)
  if window.TP_ADDITIONAL_METRIC_CALLBACK && typeof window.TP_ADDITIONAL_METRIC_CALLBACK == 'function'
    try
      window.TP_ADDITIONAL_METRIC_CALLBACK(data)
    catch error


get_device = ->
  return 'phone' if window.innerWidth <= 415
  return 'tablet' if window.innerWidth <= 748
  return 'desktop'

reach_metric_goal = (goal_name, data, count = null) ->
  data = {} unless data
  prefix = METRICS_PREFIX

  if (document.location.pathname != '/' && document.location.pathname.match("hotels")) || TPWLCONFIG.engine_type == 'hotel'
    prefix = HOTELS_METRICS_PREFIX

  goal_name = "#{prefix}_#{goal_name}" unless goal_name == 'SEARCH_REALLY_STARTED'

  data['device'] = get_device()
  data['version'] = 1
  data['ab_group'] = 'default'
  data['ab_branch'] = cookies.get('wl_ab')
  data['engine_type'] = window.TPWLCONFIG.engine_type
  data['auid'] = cookies.get('wl_auid')

  params = {'name': goal_name, 'meta': data}

  mamka('send_event', params)
  # if prefix == HOTELS_METRICS_PREFIX
  #   keen_client.addEvent(goal_name, data)

  if window.TP_ADDITIONAL_METRIC_CALLBACK && typeof window.TP_ADDITIONAL_METRIC_CALLBACK == 'function'
    try
      window.TP_ADDITIONAL_METRIC_CALLBACK(params)
    catch error

init = ->
  metrics_delegate = delegate(document.body)
  metrics_delegate.on("click", "[metric]", (event, target) ->
    dataset = {}
    for { name, value } in target.attributes when name.substring(0, 4) is 'data'
      dataset[name.substring(5)] = value
    reach_metric_goal(target.getAttribute("metric"), dataset)
  )

  dispatcher.on 'start_search',       => @reach_goal('SEARCH_START')
  dispatcher.on 'search_failed', (event, {request_id, data, params, body})  => @reach_goal('SEARCH_FAILED',{body: body})
  dispatcher.on 'search_finished',    => @reach_goal('SEARCH_RESULTS_IS_LOADED')
  dispatcher.on 'wrong_search_dates', => @reach_goal('WRONG_SEARCH_DATES')
  dispatcher.on 'buy_button_clicked', => @reach_goal('CLICK_BUY_BUTTON')
  dispatcher.on 'buy_gate_clicked',   => @reach_goal('CLICK_BUY_GATE')

  _prev_search_ids = {}
  dispatcher.on('search_id_updated', (e, params) =>
    if not _prev_search_ids[params.search_id.search_id]
      _prev_search_ids[params.search_id.search_id] = true
      @reach_goal('SEARCH_REALLY_STARTED', {search_id: params.search_id.search_id})
  )

module.exports =
  init: init
  reach_goal: reach_metric_goal
  send_metric: send_metric
